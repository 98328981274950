import './Carousel.css';
import Image from "../Image";

function Carousel({imagesList, carouselId}) {
    return (
        <div id='Carousel' className="Carousel">
            <div id={carouselId} className="carousel slide" data-bs-ride="carousel">

                {/*<!-- Indicators/dots -->
                <div className="carousel-indicators">
                    <button type="button" data-bs-target={`#${carouselId}`} data-bs-slide-to="0" className="active"></button>
                    <button type="button" data-bs-target={`#${carouselId}`} data-bs-slide-to="1"></button>
                    <button type="button" data-bs-target={`#${carouselId}`} data-bs-slide-to="2"></button>
                </div>*/}

                {/*<!-- The slideshow/carousel -->*/}
                <div className="carousel-inner">
                    {
                        imagesList ? imagesList.map((img, index) =>
                            {
                                return (
                                    <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                        <div className="card overflow-hidden border-0 rounded-0 text-center">
                                            <Image
                                                className="card-img rounded-0"
                                                src={img.src}
                                                alt={img.alt}
                                            />
                                            <div className="card-img-overlay d-flex flex-column justify-content-center text-light">
                                                {img.title ? <h3 className="card-title text-uppercase">{img.title}</h3> : null}
                                                {img.text ? <p className="card-text">{img.text}</p> : null}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        ) : null
                    }
                </div>

                {/*<!-- Left and right controls/icons -->*/}
                <button className="carousel-control-prev" type="button" data-bs-target={`#${carouselId}`} data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"></span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target={`#${carouselId}`} data-bs-slide="next">
                    <span className="carousel-control-next-icon"></span>
                </button>
            </div>
        </div>
    );
}

export default Carousel;