import {useTranslation} from "react-i18next";
import { Link } from 'react-router-dom';

function SideBar() {
    const [t,i18n] = useTranslation();
    const language = i18n.language;

    return (
        <div className="SideBar">
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar">
                <div className="offcanvas-header">
                    <div>
                        <button
                            lang="de"
                            type="button"
                            className="btn btn-link text-decoration-none text-secondary"
                            onClick={() => i18n.changeLanguage('de')}
                        >
                                {language === 'de' ? <b>de</b> : 'de'}
                        </button>
                        |
                         <button
                            lang="it"
                            type="button"
                            className="btn btn-link text-decoration-none text-secondary"
                            onClick={() => i18n.changeLanguage('it')}
                         >
                                {language === 'it' ? <b>it</b> : 'it'}
                        </button>
                    </div>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <h1 className="text-center offcanvas-title color2 special" id="offcanvasNavbarLabel">Haus Malesardi</h1>
                    <ul className="navbar-nav justify-content-end flex-grow-1 pt-3">
                        <li className="nav-item border-bottom" data-bs-dismiss="offcanvas">
                            <Link className="nav-link" to={`/${language}`}><i className="fas fa-home me-3 color2"/>{t('sidebar.home')}</Link>
                        </li>
                        <li className="nav-item border-bottom" data-bs-dismiss="offcanvas">
                            <Link className="nav-link" to={`${language}/rooms`}><i className="fas fa-bed me-3 color2"/>{t('sidebar.room')}</Link>
                        </li>
                        <li className="nav-item border-bottom" data-bs-dismiss="offcanvas">
                            <Link className="nav-link" to={`${language}/apartment`}><i className="fas fa-building me-3 color2"/>{t('sidebar.apartment')}</Link>
                        </li>
                        {/*<li className="nav-item border-bottom">
                            <a className="nav-link" href="/location"><i className="fas fa-location-dot me-3"/>{t('sidebar.location')}</a>
                        </li>
                        <li className="nav-item border-bottom">
                            <a className="nav-link" href="/pricing"><i className="fas fa-credit-card me-3"/>{t('sidebar.price')}</a>
                        </li>*/}
                    </ul>
                    <div className="container pt-5">
                        <p className="text-center"><i className="fas fa-phone me-3 color2"></i><a href={"tel:+390471510153"}>+39 0471 510 153</a></p>
                        <p className="text-center"><i className="fas fa-mobile me-3 color2"></i><a href={"tel:+393343060570"}>+39 334 306 057 0</a></p>
                        <p className="text-center"><i className="fas fa-envelope me-3 color2"></i><a href={"mailto:info@haus-malesardi.it"}>info@haus-malesardi.it</a></p>
                    </div>
                    {/*<div className="text-center pt-5" data-bs-dismiss="offcanvas">
                        <Link to={`/${language}#contact`} className="btn btn-color p-3">{t('sidebar.contact')}</Link>
                    </div>*/}
                </div>
            </div>
        </div>
    );
}

export default SideBar;
