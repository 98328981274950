import './App.css';
import React from "react";
import { Amplify } from 'aws-amplify';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import {BrowserRouter as Router} from "react-router-dom";
import AllRoutes from "./routes";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import CConsent from "./components/CConsent";
import config from './amplifyconfiguration.json';
Amplify.configure(config);

function App() {
  return (
      <I18nextProvider i18n={i18n}>
        <div className="App">
            {/*<Header src={'https://mdbcdn.b-cdn.net/img/new/slides/041.webp'} alt={"test"} src650={'https://mdbcdn.b-cdn.net/img/new/slides/041.webp'}/>*/}
            <Router>
                <Navbar/>
                <AllRoutes/>
                <CConsent/>
                <Footer/>
            </Router>
        </div>
      </I18nextProvider>
  );
}

export default App;
