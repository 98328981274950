import React from "react";
import { Route, Routes } from "react-router-dom";
import Landingpage from "../pages/Landingpage";
import Rooms from "../pages/Rooms";
import Apartment from "../pages/Apartment";
import Impressum from "../pages/Impressum";
import Privacy from "../pages/Privacy";
import Page404 from "../pages/Page404";

export default function AllRoutes() {
  return (
    <Routes>
        <Route path="/404" element={<Page404/>} />
        <Route path="/:lng?" element={<Landingpage/>} />
        <Route path="/:lng/rooms" element={<Rooms/>} />
        <Route path="/:lng/apartment" element={<Apartment/>} />
        <Route path="/:lng/impressum" element={<Impressum/>} />
        <Route path="/:lng/privacy" element={<Privacy/>} />
        <Route path="*" element={<Page404/>} />
     </Routes>
  );
}
