import './Footer.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import roterHahnLogo from "../../images/logos/roter_hahn.png";
import andrianLogo from "../../images/logos/andrian.png";
import dachmarkeLogo from "../../images/logos/dachmarke.png";
import weinstrasseLogo from "../../images/logos/weinstrasse.png";

function Footer() {
    const [t,i18n] = useTranslation();
    return (
        <footer id='Footer' className="Footer">
            <section className="footer-bgcolor-1">
                <div className="container text-center text-md-start">
                    <div className="row p-3">
                        <div className="col-md-4 col-lg-4 col-xl-4 mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                {t('footer.column1Header')}
                            </h6>
                            <p>
                                {parse(t('footer.address'))}
                            </p>
                        </div>

                        <div className="col-md-4 col-lg-4 col-xl-4 mb-2">
                            <h6 className="text-uppercase fw-bold mb-4">
                                {t('footer.column2Header')}
                            </h6>
                            <div className="row gy-3 mb-4">
                                <div className="col-sm-6">
                                    <a href={t('footer.linkRoterHahn')}><img src={roterHahnLogo} alt={t('footer.roterHahnAlt')} className="img-fluid"/></a>
                                </div>
                                <div className="col-sm-6">
                                    <a href={"https://www.suedtirol.info"}><img src={dachmarkeLogo} alt={t('footer.suedtirolAlt')} className="img-fluid"/></a>
                                </div>
                                <div className="col-sm-6">
                                    <a href={"https://www.andrian.info"}><img src={andrianLogo} alt={t('footer.tourismusverinAndrianAlt')} className="img-fluid"/></a>
                                </div>
                                <div className="col-sm-6">
                                    <a href={"https://www.suedtiroler-weinstrasse.it"}><img src={weinstrasseLogo} alt={t('footer.weinstrasseAlt')} className="img-fluid"/></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4 col-xl-4">
                            <h6 className="text-uppercase fw-bold mb-4">{t('footer.column4Header')}</h6>
                            <ul className="list-unstyled">
                                <li><i className="fas fa-envelope me-3"></i><a className={"footer-bgcolor-1"} href={"mailto:info@haus-malesardi.it"}>info@haus-malesardi.it</a></li>
                                <li><i className="fas fa-phone me-3"></i><a className={"footer-bgcolor-1"} href={"tel:+390471510153"}>+39 0471 510 153</a></li>
                                <li><i className="fas fa-mobile me-3"></i><a className={"footer-bgcolor-1"} href={"tel:+393381876077"}>+39 338 187 6077</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div className="text-center p-4 footer-bgcolor-2">
                <Link to={`/${i18n.language}/privacy`} className="text-reset">{t('footer.privacy')}</Link><br/>
                <Link to={`/${i18n.language}/impressum`} className="text-reset">{t('footer.impressum')}</Link>
            </div>
            <div className="text-center p-4 footer-bgcolor-2">
                &copy;{(new Date()).getFullYear()} Copyright: <Link className="text-reset fw-bold" to={`/${i18n.language}`}>Haus Malesardi</Link>
            </div>
        </footer>
    );
}

export default Footer;