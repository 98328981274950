import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import './Captcha.css';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import {useTranslation} from "react-i18next";

const CAPTCHA_LENGTH=6;

function Captcha({onCaptchaChange}) {
    const [t] = useTranslation();

    const [valid, setValid] = useState(false);
    const [value, setValue] = useState("");

    const handleChange = (event) => {
        const inputValue = event.target.value;
        let valid=validateCaptcha(inputValue, false);
        setValid(valid);
        setValue(inputValue);
        onCaptchaChange(valid);
    }

    useEffect(() => {
        loadCaptchaEnginge(CAPTCHA_LENGTH,'white','#0d0d0d');
    }, [])

    return (
        <div className="Captcha">
            {(valid ?
                null
                :
                <div>
                    <LoadCanvasTemplate
                        reloadColor="gray"
                        reloadText={t('captcha.reloadtext')}
                    />
                    <div className="Captcha-input">
                        <label htmlFor="captcha">{t('captcha.text')}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="captcha"
                            value={value}
                            maxLength={CAPTCHA_LENGTH}
                            onInput={handleChange}
                            required
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

Captcha.propTypes = {
    onCaptchaChange: PropTypes.func.isRequired
}

export default Captcha;