import React, {useEffect} from "react";
import '../App.css';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

function Page404() {

    useEffect(() => {
        if (!window.location.href.includes('#'))  {
            window.scrollTo(0, 0);
        }
    })

    return (
        <div className="Page404">
            <Helmet>
                <title>Error 404 - Page not found</title>
            </Helmet>
            <div className={"container mt-5 mb-5"}>
            <h1>404 - Page not found</h1>
            <div className="text-center pt-5">
            <Link to={`/`} className="btn btn-color p-3">Home</Link>
            </div>
        </div>
        </div>
    );
}

export default Page404;
