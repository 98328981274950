import './Navbar.css';
import SideBar from "./SideBar";
import {Link} from "react-router-dom";

function Navbar() {
    return (
        <nav id='Navbar' className="Navbar">
            <div className="navbar bg-light">
                <div className="container">
                    <Link className="navbar-brand" to="/"><h1 className={"pt-2 m-0 color2 special"}>Haus Malesardi</h1></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" >
                        <span className="navbar-toggler-icon"/>
                    </button>
                </div>
                <SideBar />
            </div>
        </nav>
    );
}

export default Navbar;