import React, {useEffect} from "react";
import '../App.css';
import {useTranslation} from "react-i18next";
import {useParams, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import Carousel from "../components/Carousel";
import Header from "../components/Header";
import parse from "html-react-parser";

function Rooms() {
    const [t, i18n] = useTranslation();
    const {lng} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!window.location.href.includes('#'))  {
            window.scrollTo(0, 0);
        }
        if (!['de', 'it'].includes(lng)){
            navigate('/404');
        }
    })

    return (
        <div className="Rooms">
             <Helmet>
                <meta http-equiv="content-language" content={i18n.language} />
                <title>{t('rooms.meta.title')}</title>
                <meta name="description" content={t('rooms.meta.description')}/>
                <meta name="og:title" content={t('rooms.meta.title')}/>
                <meta name="og:description" content={t('rooms.meta.description')}/>
                <meta name="og:image" content={process.env.PUBLIC_URL + '/images/jpg/zimmer1-1920.jpg'}/>
                <meta name="keywords" content={t('rooms.meta.keywords')}/>
            </Helmet>
            <Header src={process.env.PUBLIC_URL + '/images/jpg/zimmer1-1920.jpg'}
                    alt={t('rooms.headline')} headerText={t('rooms.headline')}
            />
            <section id={"equipment"}>
                <div className={"container-fluid"}>
                    <div className={"container pt-5 pb-5"}>
                        <div className={"row g-5"}>
                            <h4 className={"text-center"}>{t('rooms.header1')}</h4>
                            <div className={"col-md-6 align-self-center"}>
                                <div>
                                    {parse(t('rooms.description1'))}
                                </div>
                            </div>
                            <div className={"col-md-6 align-self-center"}>
                                 <Carousel carouselId={"carousel-rooms"} imagesList={[
                                        {src: 'zimmer1', alt:"Haus Malesardi, Zimmer 1"},
                                        {src: 'zimmer2', alt:"Haus Malesardi, Zimmer 2"},
                                        {src: 'zimmer3', alt:"Haus Malesardi, Zimmer 3"}]}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={"garden-and-parking"}>
                <div className={"container-fluid bg-section-1"}>
                    <div className={"container pt-5 pb-5"}>
                        <div className={"row g-5"}>
                            <h4 className={"text-center"}>{t('rooms.header2')}</h4>
                            <div className={"col-md-6"}>
                                <div className="card">
                                    <Carousel carouselId={"carousel-garden"} imagesList={[
                                                 {src: 'lg1', alt:"Haus Malesardi, Garten 1"},
                                                 {src: 'lg2', alt:"Haus Malesardi, Garten 2"},
                                                 {src: 'lg3', alt:"Haus Malesardi, Garten 3"},
                                                 {src: 'lg4', alt:"Haus Malesardi, Garten 4"},
                                                 {src: 'lg5', alt:"Haus Malesardi, Garten 5"}]}/>
                                    <div className="card-body">
                                         {parse(t('rooms.description2-a'))}
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-6"}>
                                <div className="card">
                                    <Carousel carouselId={"carousel-parking"} imagesList={[
                                            {src: 'pp1', alt:"Haus Malesardi, Parkplatz 1"},
                                            {src: 'pp2', alt:"Haus Malesardi, Parkplatz 2"}]}/>
                                    <div className="card-body">
                                        {parse(t('rooms.description2-b'))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={"price"}>
                <div className={"container-fluid"}>
                    <div className={"container pt-5 pb-5"}>
                        <div className={"row mb-3"}>
                            <h4 className={"text-center"}>{t('rooms.header3')}</h4>
                        </div>
                        <div className={"row p-3 bg-section-1 align-self-center"}>
                            <div className={"col-sm-6"}>
                                <p className="h4">{t('rooms.price-row1-text1')}</p>
                                <p className={"color3"}>{t('rooms.price-row1-text2')}</p>
                                <p className={'mobilcard'}>{parse(t('rooms.mobilcard-info'))}<a href={t('rooms.mobilcard-link')} target="_blank"><img src={process.env.PUBLIC_URL +"/mobilcard.svg"} /></a></p>
                            </div>
                            <div className={"col-sm-6"}>
                                <p className="h4">34,00 - 36,00€</p>
                                <p className={"color3"}>{parse(t('rooms.price-row1-text3'))}</p>
                            </div>
                        </div>
                        <div className={"row"}>
                            <p className={"text-small"}>{t('rooms.price-ortstaxe')}</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Rooms;