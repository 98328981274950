import './ContactForm.css';
import React, {useState} from "react";
import { generateClient } from 'aws-amplify/api';
import {useTranslation} from "react-i18next";
import parse from "html-react-parser";
import Captcha from "../Captcha";
import debounce from "debounce";
import * as mutations from '../../graphql/mutations';

const client = generateClient();

function ContactForm() {
    const [t,i18n] = useTranslation();
    const currentDateTime = new Date().toISOString();
    const currentDate = currentDateTime.split("T")[0];

    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState({
                    display: false,
                    type: "success",
                    header: null,
                    text: null
                });
    const [validCaptcha, setValidCaptcha] = useState(false);

    const onCaptchaChange = (value) => {
        setValidCaptcha(value);
    }

    const handleSubmit = debounce((e) => {
        e.preventDefault()
        const TTL_DELTA = 23670000; // Keep records for 9 months in seconds
        if (validCaptcha && !submitted) { // send the message as mail
            try {
                const firstName = e.target[0].value;
                const lastName = e.target[1].value;
                const email = e.target[2].value;
                const phone = null;
                const arrivalDate = e.target[3].value;
                const departureDate = e.target[4].value;
                const category = e.target[5].value;
                const adults = parseInt(e.target[6].value, 10);
                const children = parseInt(e.target[7].value, 10);
                const comment = e.target[8].value;
                const lang = i18n.language;

                client.graphql({
                  query: mutations.createContactMessage,
                  variables: {
                    input: {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phone: phone,
                        arrivalDate: arrivalDate,
                        departureDate: departureDate,
                        category: category,
                        adults: adults,
                        children: children,
                        comment: comment,
                        lang: lang,
                        ttl: (Math.floor(+new Date() / 1000) + TTL_DELTA)
                    }
                  }

                }).then((cm) => {
                    setMessage({
                        display: true,
                        type: "success",
                        header: "contactForm.successHeader",
                        text: null
                    });
                    setSubmitted(true);
                    // scroll to header
                    document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                });
            } catch (error) {
                console.error('Error uploading file: ', error);
                setMessage({
                    display: true,
                    type: "danger",
                    header: "contactForm.errorHeader",
                    text: "contactForm.errorText"
                });
                // scroll to header
                document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    },300, true);

    return (
        <div className="ContactForm" id="contact-form">
            {submitted ? null :
              <form className="form-floating row g-3" onSubmit={handleSubmit}>
                <div className="col-md-4">
                    <div className="form-floating">
                        <input type="text" className="form-control" id="firstName"/>
                        <label htmlFor="floatingInput" className="form-label" >{t('contactForm.firstName')}</label>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-floating">
                        <input type="text" className="form-control" id="lastName" required/>
                        <label htmlFor="floatingInput" className="form-label" >{t('contactForm.lastName')}*</label>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-floating">
                        <input type="email" className="form-control" id="email" required/>
                        <label htmlFor="floatingInput" className="form-label" >{t('contactForm.email')}*</label>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-floating">
                        <input type="date" className="form-control" id="arrival" min={currentDate} required/>
                        <label htmlFor="floatingInput" className="form-label" >{t('contactForm.arrival')}*</label>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-floating">
                        <input type="date" className="form-control" id="departure" required/>
                        <label htmlFor="floatingInput" className="form-label" >{t('contactForm.departure')}*</label>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-floating">
                        <select className="form-select" id="floatingSelect" defaultValue={"ROOM"} required>
                            <option value="ROOM">{t('contactForm.room')}</option>
                            <option value="APARTMENT">{t('contactForm.apartment')}</option>
                        </select>
                        <label htmlFor="floatingSelect">{t('contactForm.category')}*</label>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-floating">
                        <input type="number" className="form-control" id="adults" defaultValue="2" min="1" max="11" required/>
                        <label htmlFor="floatingInput" className="form-label">{t('contactForm.adults')}*</label>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-floating">
                        <input type="number" className="form-control" id="children" defaultValue="0" min="0" max="11"/>
                        <label htmlFor="floatingInput" className="form-label" >{t('contactForm.children')}</label>
                    </div>
                </div>
                <div className="col-md-12 h-25">
                    <div className="form-floating">
                        <textarea className="form-control h-25" rows="5" placeholder="Schreiben Sie uns eine Nachricht"
                                  id="floatingTextarea"></textarea>
                        <label htmlFor="floatingTextarea">{t('contactForm.message')}</label>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="invalidCheck" required />
                        <label className="form-check-label" htmlFor="invalidCheck">
                            {parse(t('contactForm.checkbox1'))}
                        </label>
                    </div>
                </div>
                <Captcha onCaptchaChange={onCaptchaChange}/>
                <div className="col-md-12">
                    <button type="submit" className="btn btn-color">{t('contactForm.submit')}</button>
                </div>
              </form>
            }
            { message?.display ?
                <div className={`alert alert-${message?.type ? message?.type : "info"}`} role="alert">
                  {message?.header ? <p className="fw-bold">{t(message?.header)}</p> : null}
                  {message?.text ? <p>{t(message?.text)}</p> : null}
                </div>
                :
                null
            }
        </div>
    );
}

export default ContactForm;
