import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationIT from './locales/it/translation.json';
import translationDE from './locales/de/translation.json';

// the translations
const resources = {
    it: {
        translation: translationIT,
    },
    de: {
        translation: translationDE,
    }
};

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "de", // use en if detected lng is not available
        whitelist: ["de", "it"],
        detection: {
            order: ['path'],
            lookupFromPathIndex: 0,
            checkWhiteList: true
        },
        //debug: true,
        defaultNS: 'translation',
        supportedLngs: ["it", "de"],
        //keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true,
        }
    });

export default i18n;