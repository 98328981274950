import React from "react";
import './LandingHeadLine.css';
import {useTranslation} from "react-i18next";
import Image from "../Image";

function LandingHeadline({src, alt}) {
    const [t] = useTranslation();
    return (
        <header id='Header' className="LandingHeadline">
            <div className="container-fluid p-0 position-relative">
                <Image
                    className="LandingHeadlineImage"
                    src={src}
                    alt={alt}
                />
                <div className="container d-none d-lg-block">
                    <div className="mask position-absolute">
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <div>
                                <h1 className="text-white mb-5 special">{t('landingpage.headline.text1')}</h1>
                                <p className="text-white mb-0">{t('landingpage.headline.text2')}</p>
                                <p className="text-white mb-0">{t('landingpage.headline.text3')}</p>
                                <p className="text-white mb-0">{t('landingpage.headline.text4')}</p>
                                <p className="text-white mb-0">{t('landingpage.headline.text5')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default LandingHeadline;
