import './Badge.css';
import React from "react";

function Badge({iconSVG, text}) {

    return (
        <div className="Badge">
            <div>
              {iconSVG ? <img src={iconSVG} alt={text}/> : null}
              <p><small>{text}</small></p>
            </div>
        </div>
    );
}

export default Badge;
