import './Header.css';
import React from "react";

function Header({webp, src, alt, headerText}) {
    return (
        <header id='Header' className="Header">
            <div className="bg-image">
                <picture>
                    {webp ? <source srcSet={webp}/> : null}
                    <img src={src} className="img-fluid w-100" alt={alt}/>
                </picture>
                {
                    headerText ?
                        <div className="mask">
                           <div className="container h-100">
                            <div className="d-flex align-items-end h-100">
                                <h1 className={"header-text"}>{headerText}</h1>
                            </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        </header>
    );
}

export default Header;