import React, {useEffect} from "react";
import '../App.css';
import {useTranslation} from "react-i18next";
import {useParams, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import parse from "html-react-parser";

function Impressum() {
    const [t, i18n] = useTranslation();
    const {lng} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!window.location.href.includes('#'))  {
            window.scrollTo(0, 0);
        }
        if (!['de', 'it'].includes(lng)){
            navigate('/404');
        }
    })

    return (
        <div className="Impressum">
            <Helmet>
                <meta http-equiv="content-language" content={i18n.language} />
                <meta name="robots" content="noindex"/>
                <title>{`Haus Malesardi - ${t('impressum.header')}`}</title>
            </Helmet>
            <div className={"container mt-5 mb-5"}>
                <h1>{t('impressum.header')}</h1>
                <div className="card mb-5">
                    <div className="card-body">
                        <h2 className="card-title">{t('impressum.card-title')}</h2>
                        <p className="card-text">
                             {parse(t('impressum.card-text'))}
                        </p>
                    </div>
                </div>
                {parse(t('impressum.content2'))}
            </div>
        </div>
    );
}

export default Impressum;