import React from "react";
import {useTranslation} from "react-i18next";
import CookieConsent from "react-cookie-consent";

function CConsent() {
    const [t] = useTranslation();
    return (
       <CookieConsent 
           	buttonClasses="btn btn-color"
  		containerClasses="alert"
  		buttonStyle={{
    			background: "var(--secondary-color)",
   			color: "white",
    			fontWeight: "normal"
 		}}
 		buttonText={t('cookieConsent.btnText')}
  	   >
  	   {t('cookieConsent.text')}
  	   </CookieConsent>
    );
}


export default CConsent;
