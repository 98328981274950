import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './MultiItemCarousel.css';
import Image from "../Image";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 701 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 700, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

function MultiItemCarousel({imagesList}) {
    return (
        <div id='MultiItemCarousel' className="MultiItemCarousel">
            <Carousel
                swipeable={true}
                draggable={false}
                showDots={true}
                centerMode={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={1000}
                containerClass="carousel-container"
                //removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
            >
                {
                    imagesList ? imagesList.map((img, index) =>
                        {
                            return (
                                    <div key={index} className="card overflow-hidden border-0 rounded-0 text-center">
                                        <Image
                                            className="card-img rounded-0"
                                            src={img.src}
                                            alt={img.alt}
                                        />
                                        <div className="card-img-overlay d-flex flex-column justify-content-center text-light">
                                            {img.title ? <h3 className="card-title text-uppercase">{"titel"}</h3> : null}
                                            {img.text ? <p className="card-text">{img.text}</p> : null}
                                        </div>
                                    </div>
                            )
                        }
                    ) : null
                }
            </Carousel>
        </div>
    );
}

export default MultiItemCarousel;