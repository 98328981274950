import React, {useEffect} from "react";
import '../App.css';
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import {useParams, useNavigate, Link} from "react-router-dom";
import ScrollToAnchor from "../components/ScrollToAnchor";
import Carousel from "../components/Carousel";
import Location from "../components/Location";
import ContactForm from "../components/ContactForm";
import MultiItemCarousel from "../components/MultiItemCarousel";
import LandingHeadline from "../components/LandingHeadline";
import Image from "../components/Image";
import parse from "html-react-parser";


function Landingpage() {
    const [t,i18n] = useTranslation();
    const {lng} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!window.location.href.includes('#'))  {
            window.scrollTo(0, 0);
        }
        if (!lng) {
            navigate(`/${i18n.language}/`, { replace: true });
        } else if (!['de', 'it'].includes(lng)){
            navigate('/404');
        }
    })

    return (
        <div className="Landingpage">
            <ScrollToAnchor/>
            <Helmet
                script={[
                    helmetJsonLdProp(t('jsonld', { returnObjects: true }))
                    ]}
            />
            <Helmet>
                <meta http-equiv="content-language" content={i18n.language} />
                <title>{t('landingpage.meta.title')}</title>
                <meta name="description" content={t('landingpage.meta.description')}/>
                <meta name="og:title" content={t('landingpage.meta.title')}/>
                <meta name="og:description" content={t('landingpage.meta.description')}/>
                <meta name="og:image" content={process.env.PUBLIC_URL + '/images/jpg/haus1-1-1920.jpg'}/>
                <meta name="keywords" content={t('landingpage.meta.keywords')}/>
                <link rel="canonical" href={`http://www.haus-malesardi.it/${i18n.language}`} />
            </Helmet>
            <LandingHeadline
                src={'haus1-1'}
                alt={'Haus Malesardi'}
            />
            <section id={"welcome"}>
                <div className={"container-fluid"}>
                    <div className={"container pt-5 pb-5"}>
                        <div className={"row g-5"}>
                            <h4 className={"text-center"}>{t('landingpage.welcome.header1')}</h4>
                            <div className={"col-md-6 align-self-center"}>
                                <div>
                                    <h1 className={"pb-3 special color2"}>{t('landingpage.welcome.header2')}</h1>
                                    {parse(t('landingpage.welcome.description'))}
                                </div>
                            </div>
                            <div className={"col-md-6 align-self-center"}>
                                <Image
                                    className="card-img rounded-0"
                                    src={'lg1'}
                                    alt={"Haus Malesardi Bild vom Eingang"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={"offer"}>
                <div className={"container-fluid bg-section-1"}>
                    <div className={"container pt-5 pb-5"}>
                        <div className={"row g-5"}>
                            <h4 className={"text-center"}>{t('landingpage.offer.header1')}</h4>
                            <h1 className={"text-center special color2"}>{t('landingpage.offer.header2')}</h1>
                            <div className={"col-md-6"}>
                                <div className="card">
                                    <div className="card-header pt-3 bg-color2">
                                    <h3>{t('landingpage.offer.roomHeader')}</h3>
                                    </div>
                                    <Carousel carouselId={"rooms"} imagesList={[
                                        {src: 'zimmer1', alt:"Haus Malesardi, Zimmer 1"},
                                        {src: 'zimmer2', alt:"Haus Malesardi, Zimmer 2"},
                                        {src: 'zimmer3', alt:"Haus Malesardi, Zimmer 3"}]}/>
                                    <div className="card-body">
                                        {parse(t('landingpage.offer.roomDescription'))}
                                        <Link to={`/${i18n.language}/rooms`} className="btn btn-color">{t('landingpage.global.moreBtnText')}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-6"}>
                                <div className="card">
                                    <div className="card-header pt-3 bg-color2">
                                        <h3>{t('landingpage.offer.appartmentHeader')}</h3>
                                    </div>
                                    <Carousel carouselId={"appartment"} imagesList={[
                                        {src: 'fw1', alt:"Haus Malesardi, Ferienhaus"},
                                        {src: 'fw3', alt:"Haus Malesardi, Ferienhaus, Küche"},
                                        {src: 'fw2', alt:"Haus Malesardi, Ferienhaus, Zimmer"}]}/>
                                    <div className="card-body">
                                        {parse(t('landingpage.offer.appartmentDescription'))}
                                         <Link to={`/${i18n.language}/apartment`} className="btn btn-color">{t('landingpage.global.moreBtnText')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={"pictures"}>
                <div className="container-fluid pt-3 pb-3">
                        <MultiItemCarousel imagesList={[
                                {src: 'lg3', alt:"Haus Malesardi, Garten 1"},
                                {src: 'lg4', alt:"Haus Malesardi, Garten 2"},
                                {src: 'lg5', alt:"Haus Malesardi, Garten 3"},
                                {src: 'pp1', alt:"Haus Malesardi, Parkplatz 1"},
                                {src: 'pp2', alt:"Haus Malesardi, Parkplatz 2"}
                            ]}/>
                </div>
            </section>
            <section id={"contact"}>
                <div className={"container-fluid bg-section-1"}>
                    <div className={"container pt-5 pb-5"}>
                        <h4 className={"text-center pb-5"}>{t('landingpage.contact.header1')}</h4>
                        <ContactForm />
                    </div>
                </div>
            </section>
            <section id={"location"}>
                <Location />
            </section>
        </div>
    );
}

export default Landingpage;