import React from "react";
import '../../App.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

const position = [46.52053719061488, 11.231039141522055]

function Location() {
    return (
        <div className="Location">
            <MapContainer center={position} zoom={14} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                    <Popup>
                        Haus Malesardi
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    );
}

export default Location;