import React, {useEffect} from "react";
import '../App.css';
import {useTranslation} from "react-i18next";
import {useParams, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import parse from "html-react-parser";

function Privacy() {
    const [t, i18n] = useTranslation();
    const {lng} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!window.location.href.includes('#'))  {
            window.scrollTo(0, 0);
        }
        if (!['de', 'it'].includes(lng)){
            navigate('/404');
        }
    })

    return (
        <div className="Privacy">
            <Helmet>
                <meta http-equiv="content-language" content={i18n.language} />
                <meta name="robots" content="noindex"/>
                <title>{`Haus Malesardi - ${t('privacy.header')}`}</title>
            </Helmet>
            <div className={"container mt-5 mb-5"}>
            <h1>{t('privacy.header')}</h1>
            {parse(t('privacy.content'))}
        </div>
        </div>
    );
}

export default Privacy;
