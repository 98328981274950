import React, {useEffect} from "react";
import '../App.css';
import {useTranslation} from "react-i18next";
import {useParams, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import Carousel from "../components/Carousel";
import Header from "../components/Header";
import Badge from "../components/Badge";
import parse from "html-react-parser";
import fridgeIcon from "../icons/fridge.svg";
import ovenIcon from "../icons/oven.svg";
import coffeIcon from "../icons/coffee-maker.svg";
import ovenTrayIcon from "../icons/oven-tray.svg";
import dishIcon from "../icons/dish-fork.svg";

function Apartment() {
    const [t, i18n] = useTranslation();
    const {lng} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!window.location.href.includes('#'))  {
            window.scrollTo(0, 0);
        }
        if (!['de', 'it'].includes(lng)){
            navigate('/404');
        }
    })

    return (
        <div className="Apartment">
            <Helmet>
                <meta http-equiv="content-language" content={i18n.language} />
                <title>{t('apartment.meta.title')}</title>
                <meta name="description" content={t('apartment.meta.description')}/>
                <meta name="og:title" content={t('apartment.meta.title')}/>
                <meta name="og:description" content={t('apartment.meta.description')}/>
                <meta name="og:image" content={process.env.PUBLIC_URL + '/images/jpg/fw3-1920.jpg'}/>
                <meta name="keywords" content={t('apartment.meta.keywords')}/>
            </Helmet>
             <Header src= {process.env.PUBLIC_URL + '/images/jpg/view1-1920.jpg'}
                    alt={t('apartment.headline')} headerText={t('apartment.headline')}
            />
            <section id={"equipment"}>
                <div className={"container-fluid"}>
                    <div className={"container pt-5 pb-5"}>
                        <div className={"row g-5"}>
                            <h4 className={"text-center"}>{t('apartment.header1')}</h4>
                            <div className={"col-md-6 align-self-center"}>
                                <div>
                                    {parse(t('apartment.description1'))}
                                </div>
                                <div className={"row"}>
                                    <p>{t('apartment.description2')}</p>
                                    <div className={"col"}>
                                    <Badge iconSVG={fridgeIcon}
                                        text={t('apartment.fridge')}
                                    />
                                    </div>
                                    <div className={"col"}>
                                    <Badge iconSVG={ovenIcon}
                                        text={t('apartment.oven')}
                                    />
                                    </div>
                                    <div className={"col"}>
                                    <Badge iconSVG={ovenTrayIcon}
                                        text={t('apartment.oven-tray')}
                                    />
                                    </div>
                                    <div className={"col"}>
                                    <Badge iconSVG={dishIcon}
                                        text={t('apartment.dishes')}
                                    />
                                    </div>
                                    <div className={"col"}>
                                    <Badge iconSVG={coffeIcon}
                                        text={t('apartment.coffe-machine')}
                                    />
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-6 align-self-center"}>
                                 <Carousel carouselId={"carousel-apartment"} imagesList={[
                                        {src: 'fw1', alt:"Haus Malesardi, Ferienhaus"},
                                        {src: 'fw2', alt:"Haus Malesardi, Ferienhaus, Zimmer"},
                                        {src: 'fw3', alt:"Haus Malesardi, Ferienhaus, Küche"},
                                        {src: 'lg3', alt:"Haus Malesardi, Ferienhaus, Garten 1"},
                                        {src: 'lg4', alt:"Haus Malesardi, Ferienhaus, Garten 2"},
                                        {src: 'lg5', alt:"Haus Malesardi, Ferienhaus, Garten 3"}]}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={"equipment"}>
            <div className={"container-fluid bg-section-1"}>
                    <div className={"container pt-5 pb-5"}>
                        <div className={"row"}>
                        <div className={"col-sm-12 align-self-center"}>
                             {parse(t('apartment.description3'))}
                         </div>
                         </div>
                         </div>
                         </div>
            </section>
            <section id={"price"}>
                <div className={"container-fluid"}>
                    <div className={"container pt-5 pb-5"}>
                        <div className={"row mb-3"}>
                            <h4 className={"text-center"}>{t('apartment.header3')}</h4>
                        </div>
                        <div className={"row p-3 bg-section-1 align-self-center"}>
                            <div className={"col-sm-6"}>
                                <p className="h4">{t('apartment.price-row1-text1')}</p>
                                <p className={"color3"}>{t('apartment.price-row1-text2')}</p>
                                <p className={'mobilcard'}>{parse(t('rooms.mobilcard-info'))}<a href={t('rooms.mobilcard-link')} target="_blank"><img src={process.env.PUBLIC_URL +"/mobilcard.svg"} /></a></p>
                            </div>
                            <div className={"col-sm-6"}>
                                <p className="h4">85,00€ - 90,00€</p>
                                <p className={"color3"}>{parse(t('apartment.price-row1-text3'))}</p>
                            </div>
                        </div>
                        <div className={"row"}>
                            <p className={"text-small"}>{t('apartment.price-ortstaxe')}</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Apartment;