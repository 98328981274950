import React from "react";

function Image({src, alt, className}) {
    const jpgPath = `${process.env.PUBLIC_URL}/images/jpg/${src}`;
    const webpPath = `${process.env.PUBLIC_URL}/images/webp/${src}`;
    return (
        <picture>
             <source type="image/webp"
                sizes="(max-width: 200px) 200px,
                        (max-width: 300px) 300px,
                        (max-width: 400px) 400px,
                        (max-width: 500px) 500px,
                        (max-width: 600px) 600px,
                        (max-width: 700px) 700px,
                        (max-width: 800px) 800px,
                        (max-width: 1024px) 1024px,
                        (max-width: 1200px) 1200px,
                        (max-width: 1400px) 1400px,
                        100vw"
                srcSet={`${webpPath}-200.webp 200w,
                  ${webpPath}-300.webp 300w,
                  ${webpPath}-400.webp 400w,
                  ${webpPath}-500.webp 500w,
                  ${webpPath}-600.webp 600w,
                  ${webpPath}-700.webp 700w,
                  ${webpPath}-800.webp 800w,
                  ${webpPath}-1024.webp 1024w,
                  ${webpPath}-1200.webp 1200w,
                  ${webpPath}-1400.webp 1400w,
                  ${webpPath}-1920.webp 1920w
                `}
             />
            <img
                className={className}
                srcSet={`${jpgPath}-200.webp 200w,
                  ${jpgPath}-300.webp 300w,
                  ${jpgPath}-400.webp 400w,
                  ${jpgPath}-500.webp 500w,
                  ${jpgPath}-600.webp 600w,
                  ${jpgPath}-700.webp 700w,
                  ${jpgPath}-800.webp 800w,
                  ${jpgPath}-1024.webp 1024w,
                  ${jpgPath}-1200.webp 1200w,
                  ${jpgPath}-1400.webp 1400w,
                  ${jpgPath}-1920.webp 1920w
                `}
                sizes="(max-width: 200px) 200px,
                        (max-width: 300px) 300px,
                        (max-width: 400px) 400px,
                        (max-width: 500px) 500px,
                        (max-width: 600px) 600px,
                        (max-width: 700px) 700px,
                        (max-width: 800px) 800px,
                        (max-width: 1024px) 1024px,
                        (max-width: 1200px) 1200px,
                        (max-width: 1400px) 1400px,
                        100vw"
                src={`${jpgPath}-1920.jpg`}
                alt={alt}
            />
        </picture>
    );
}

export default Image;
